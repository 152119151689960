import React from "react";
import logoWhite from "../../assets/logo/AAPALI  final wi.png";
import "./Footer.css";
import facebookicon from "../../assets/face.png";
import instagarmicon from "../../assets/instagram.png";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div className="footer">
      <div className="logofooter">
        <img src={logoWhite} alt="" />
      </div>
      <div className="menufooter">
        <div>
          <ul className="menu-list">
            <li>
              <Link className="menu-list-link" to={"/"}>
                home
              </Link>
            </li>
            <li>
              <Link className="menu-list-link" to={"/aboutus"}>
                about us
              </Link>
            </li>
            <li>
              <Link className="menu-list-link" to={"/contactus"}>
                contact us
              </Link>
            </li>
            <li>
              <Link className="menu-list-link" to={"/iso-certifications"}>
                certifications
              </Link>
            </li>
          </ul>
        </div>

        <div className="iconfooter">
          <div>
            <img src={instagarmicon} alt=" instagram icon" />
          </div>
          <div>
            <img src={facebookicon} alt="facebook icon" />
          </div>
        </div>
      </div>
      <div>
        <div className="footerHeading">Aapali Store</div>

        <ul className="menu-list">
          <li>
            <a className="menu-list-link" href="aapalistore.html">
              Gift Items
            </a>
          </li>
          <li>
            <a className="menu-list-link" href="aapalistore.html">
              Printing & Stationary
            </a>
          </li>
          <li>
            <a className="menu-list-link" href="aapalistore.html">
              Posters & Brochures
            </a>
          </li>
          <li>
            <a className="menu-list-link" href="aapalistore.html">
              Healthcare Products
            </a>
          </li>
        </ul>
      </div>
      <div className="contactfooter">
        {/* <div className="footerHeading">address</div>
        <div className="footerDetail">
          Gulf Shore Group Building No. 15, Street 3080, Zone 91, Birkat Al
          Awamer, Doha,Qatar
        </div> */}
        <div className="footerHeading">phone</div>
        <div className="footerDetail">
          <a className="footerDetail" href="tel:0504462755">
            0091 971444 661 662(India)
          </a>
          <br></br>
          <a className="footerDetail" href="tel:00971504462755">
            00971 50 4462755(UAE)
          </a>
        </div>
        {/* <div className="footerHeading">e-mail</div>
        <div className="footerDetail">info@gulfshore-group.com</div> */}
      </div>
    </div>
  );
}

export default Footer;
