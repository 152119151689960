import React from "react";
// import bg1 from "../../assets/background/slider-1 1.png";
// import bg2 from "../../assets/background/slider-2 1.png";
// import bg3 from "../../assets/background/image 35.png";
// import bg4 from "../../assets/background/slider-3 1.png";
import contentimg1 from "../../assets/content-img/businessman-showing-changes-report.jpg";
import contentimg2 from "../../assets/content-img/medium-shot-woman-working-laptop.jpg";

// import card1 from "../../assets/card-img/11.jpg";
// import card2 from "../../assets/card-img/maintenance 1.png";
// import card3 from "../../assets/card-img/building 1.png";
// import card4 from "../../assets/card-img/house-rental 1.png";
// import card5 from "../../assets/card-img/gear 1.png";
// import card6 from "../../assets/card-img/inspecting 1.png";

import sadiflag from "../../assets/flag/saudi-arabia_5111777.png";
import duabaiflag from "../../assets/flag/united-arab-emirates_5921904.png";

import canadaflag from "../../assets/flag/canada_9906451.png";
import ukflag from "../../assets/flag/uk_4628638.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { motion } from "framer-motion";
import Slider from "react-slick";

import "./Home.css";

import Carousel from "react-bootstrap/Carousel";
import { Col, Container, Row } from "react-bootstrap";
import video from "../../assets/bg-video.mp4";
import client1 from "../../assets/client-logo/image 20.png";
import client2 from "../../assets/client-logo/image 21.png";
import client3 from "../../assets/client-logo/image 22.png";
import client4 from "../../assets/client-logo/image 23.png";
import client5 from "../../assets/client-logo/image 24.png";
import client6 from "../../assets/client-logo/image 25.png";
import client7 from "../../assets/client-logo/image 26.png";
import Certification from "../certification/Certification";
import VisionSection from "../vision/VisionSection";
import { Link } from "react-router-dom";
// import { TypeAnimation } from "react-type-animation";

const clientImage = [
  {
    img: client1,
    id: "11",
  },
  {
    img: client2,
    id: "12",
  },
  {
    img: client3,
    id: "13",
  },
  {
    img: client4,
    id: "14",
  },
  {
    img: client5,
    id: "15",
  },
  {
    img: client6,
    id: "16",
  },
  {
    img: client7,
    id: "17",
  },
];

function Home() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 5000,
    cssEase: "linear",
  };

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <div className="home">
        <div className="hero">
          <video autoPlay loop muted playsInline id="video">
            <source src={video} type="video/mp4" />
          </video>
          <div className="overlay10"></div>
          <div className="hero-slider-content">
            <Carousel pause={false}>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    MORE EXPENSES AND LOW INCOME?
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">WHERE DO I BEGIN?</div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    CONSIDERING A CHANGE IN YOUR CURRENT BUSINESS?
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
              <Carousel.Item interval={3000}>
                <div className="hero-slider-item">
                  <div className="slider-heading">
                    STARTING A BUSINESS IN UAE?
                  </div>
                  <div className="button-hero">
                    <Link className="slider-btn-text" to={"/contactus"}>
                      <div className="slider-btn">CONTACT US</div>
                    </Link>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        {/* <div className="home-slider">
          <Carousel fade>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg1}
                text="First slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  when services matters your choice is simple
                </div>

                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg2}
                text="Second slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  GULF SHORE GROUP we provide high quality service
                </div>
                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg3}
                text="Third slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  when services matters your choice is simple
                </div>
                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
            <Carousel.Item interval={2000}>
              <img
                style={{ width: "100%", height: "100vh", objectFit: "cover" }}
                src={bg4}
                text="Fourth slide"
                alt="oil rig in the sea"
              />
              <div className="slider-content">
                <div className="slider-heading">
                  GULF SHORE GROUP 17 years of Engineering Experience
                </div>
                <button className="slider-btn">CONTACT US</button>
              </div>
            </Carousel.Item>
          </Carousel>
        </div> */}
        {/* <TypeAnimation
                      sequence={[
                        "when services matters your choice is simple",
                        1000,
                      ]}
                      wrapper="span"
                      speed={50}
                      style={{ fontSize: "2em", display: "inline-block" }}
                      repeat={Infinity}
                    /> */}

        <Container>
          <Row>
            <Col>
              <motion.adiv
                initial={{ translateX: -50 }}
                whileInView={{ translateX: 0 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true }}
                className="flagSection"
              >
                <div className="flagitem">
                  <img className="flag" src={sadiflag} />
                  <b>Saudi Arabia</b>
                </div>
                <div className="flagitem">
                  <img className="flag" src={duabaiflag} />
                  <b>UAE</b>
                </div>
                <div className="flagitem">
                  <img className="flag" src={canadaflag} />
                  <b>Canada</b>
                </div>
                <div className="flagitem">
                  <img className="flag" src={ukflag} />
                  <b>UK</b>
                </div>
              </motion.adiv>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6}>
              <div className="content-1-img-section">
                <Carousel pause={false}>
                  <Carousel.Item interval={2000}>
                    <div className="content-1-img">
                      <img src={contentimg1} alt="oil pipe" />
                    </div>
                  </Carousel.Item>
                  <Carousel.Item interval={2000}>
                    <div className="content-1-img">
                      <img src={contentimg2} alt="oil pipe" />
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>

            <Col>
              <div className="content-1">
                <div className="content-1-details">
                  <h1 className="heading-1">About Us</h1>
                  <h1 className="heading">Aapali change you & your life</h1>
                </div>
                <div className="content-1-details">
                  <p className="para">
                    Aapali is the culmination of the efforts of dedicated
                    individuals and a professional team boasting over 40 years
                    of collective experience across diverse industries. Our
                    expertise spans business setup, including typing centers,
                    translation offices, VAT/tax consultancy, educational
                    institutions, document and shipment delivery offices, and
                    the establishment of resorts and farmhouses. We've also
                    navigated various roles, from grocery sales to positions in
                    accounting, secretarial work, purchasing, receptionist
                    duties, translation, auditing, tax consultancy, training,
                    tour operator, and more. Our journey encompasses experience
                    in supermarkets, travel and tourism, accounts, purchases,
                    and warehouse management.
                  </p>
                </div>
                <div className="content-1-details">
                  <Link to={"aboutus"}>
                    <button className="content-btn">MORE INFO</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>

          <Row></Row>

          {/* <div className="home-section2">
            <div className="card-section">
              <div className="card">
                <div className="card-logo">
                  <Link to={"/oilandgas"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>
                      <img className="card-img" src={card1} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">OIL & GAS</div>

                <Link className="view-btn" to={"/oilandgas"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/engineering"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card2} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">ENGINEERING</div>
                <Link className="view-btn" to={"/engineering"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/pre-engineered"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card3} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">pre engineered building</div>
                <Link className="view-btn" to={"/pre-engineered"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/off-shore-on-shore-rental"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card4} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">offshore/onshore rental</div>
                <Link className="view-btn" to={"/off-shore-on-shore-rental"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/maintanance-refurbishment"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card5} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">maintenance & refurbishment</div>
                <Link className="view-btn" to={"/maintanance-refurbishment"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
              <div className="card">
                <div className="card-logo">
                  <Link to={"/load-testing-and-inspection"}>
                    <motion.div
                      whileHover={{ scale: 1.2, rotate: -360 }}
                      whileTap={{ scale: 0.8, borderRadius: "100%" }}
                      className="v-icon"
                    >
                      <svg
                        className="dot"
                        xmlns="http://www.w3.org/2000/svg"
                        width="60"
                        height="60"
                        viewBox="0 0 60 60"
                        fill="none"
                      >
                        <circle cx="30" cy="30" r="30" fill="#FFE605" />
                      </svg>

                      <img className="card-img" src={card6} alt="" />
                    </motion.div>
                  </Link>
                </div>
                <div className="card-text">load testing & inspections</div>
                <Link className="view-btn" to={"/load-testing-and-inspection"}>
                  <div>VIEW DETAILS</div>
                </Link>
              </div>
            </div>
          </div> */}
        </Container>
        <div className="card-section">
          <div className="box-section-heading">
            <div className="heading-text">Our Services</div>
            <div className="heading-desc">An overview of what we do.</div>
          </div>
          <div className="card-box card1">
            <div className="overlay-box"></div>
            <div className="card-btn">Family visa</div>
          </div>
          <div className="card-box card2">
            <div className="card-btn">Business setup</div>
            <div className="overlay-box"></div>
          </div>
          <div className="card-box card3">
            <div className="card-btn">attestation</div>
            <div className="overlay-box"></div>
          </div>
          <div className="card-box card4">
            <div className="card-btn">Translation</div>
            <div className="overlay-box"></div>
          </div>
          <div className="card-box card5">
            <div className="card-btn">Branding & Marketing</div>
            <div className="overlay-box"></div>
          </div>
        </div>
        {/* 
        <Container>
          <div className="features-wrap">
            <div className="section-title text-center">
              <h2 className="title">
                How <span>Aapali</span> works
              </h2>
            </div>

            <div className="features-content-wrap">
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img
                        src="assets/images/icons8-short-hair-lady-question-mark-100.png"
                        alt=""
                      />
                    </div>

                    <div className="features-content">
                      <h3 className="title">PSPR</h3>
                      <h5 style={{ color: "#000000" }}>
                        (Problem{" "}
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>
                        Solution
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>
                        Processing
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>{" "}
                        Result)
                      </h5>
                      <div
                        style={{
                          textAlign: "left",
                          paddingLeft: "20px",
                          paddingRight: " 20px",
                        }}
                      >
                        <p>
                          <li>We understand your problem.</li>
                          <li>Provide the best solution.</li>
                          <li>Once you agree, we process the task.</li>
                          <li>
                            You receive the desired result, and we
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;charge for our
                            service.
                          </li>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img
                        src="assets/images/icons8-government-100.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content">
                      <h3 className="title">PE3</h3>
                      <h5 style={{ color: "#000000" }}>
                        (Problem{" "}
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>
                        Emirates
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>{" "}
                        Govt Dept.Embassy)
                      </h5>
                      <div
                        style={{
                          textAlign: " left",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <p>
                          <li>
                            Assess whether the task is personal, family,
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;or company-related.
                          </li>
                          <li>Determine your emirate.</li>
                          <li>
                            Direct the task to the relevant government,
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;embassy, or category.
                          </li>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img
                        src="assets/images/icons8-verified-account-100.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content">
                      <h3 className="title">CPA</h3>
                      <h5 style={{ color: "#000000" }}>
                        (Confirmation{" "}
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>
                        Payment
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>{" "}
                        Accept Result)
                      </h5>
                      <div
                        style={{
                          textAlign: "left",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                        }}
                      >
                        <p>
                          <li>Confirm the task and make payment.</li>
                          <li>
                            We initiate the task, with completion times
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;varying.
                          </li>
                          <li>
                            It could be done within hours, on the same
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;day, or later.
                          </li>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img src="assets/images/icons8-market-100.png" alt="" />
                    </div>
                    <div className="features-content">
                      <h3 className="title">The Market Study</h3>
                      <div
                        style={{
                          textAlign: "left",
                          paddingLeft: " 20px",
                          paddingRight: "20px",
                        }}
                      >
                        <p>
                          <li>
                            Conduct Market Study: - Government Office
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Count
                          </li>
                          <li>
                            We initiate the task, with completion times
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;varying.
                          </li>
                          <li>
                            It could be done within hours, on the same
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;day, or later.
                          </li>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img src="assets/images/icons8-task-100.png" alt="" />
                    </div>
                    <div className="features-content">
                      <h3 className="title">WT(3 way task holding)</h3>
                      <div
                        style={{
                          textAlign: "left",
                          paddingLeft: "35px",
                          paddingRight: "20px",
                        }}
                      >
                        <p>
                          <li style={{ listStyle: "decimal" }}>
                            By mail/whatsap
                          </li>
                          <li style={{ listStyle: "decimal" }}>
                            We initiate the task, with completion times
                            &nbsp;&nbsp;&nbsp;&nbsp;varying.
                          </li>
                          <li style={{ listStyle: "decimal" }}>
                            It could be done within hours, on the same
                            &nbsp;&nbsp;&nbsp;&nbsp;day, or later.
                          </li>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img src="assets/images/icons8-ok-hand-100.png" alt="" />
                    </div>
                    <div className="features-content">
                      <h3 className="title">Value based decision making</h3>
                      <p style={{ textAlign: "justify" }}>
                        Listen the customers ask{" "}
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>
                        Make sure the answer is best, last and final{" "}
                        <span>
                          <img
                            src="assets/images/icons8-right-arrow-20.png"
                            alt=""
                          />
                        </span>
                        Make sure customer satisfaction speed, quality, error
                        free with reasonable service charge.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img
                        src="assets/images/icons8-iphone-spinner-100.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content">
                      <h3 className="title">The way of processing</h3>
                      <div style={{ textAlign: "left", paddingLeft: "90px" }}>
                        <p>
                          <li>In house processing</li>
                          <li>Backoffice transfering</li>
                          <li>Third party outsourcing</li>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="features-item text-center">
                    <div className="features-img">
                      <img
                        src="assets/images/icons8-man-window-lock-100.png"
                        alt=""
                      />
                    </div>
                    <div className="features-content">
                      <h3 className="title">
                        Ethic and confidential and keeping privacy{" "}
                      </h3>
                      <p>Keep customers valued documents, and datas.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container> */}

        <VisionSection />

        {/* <div>
          <div className="home-section4">
            <div className="section-heading">
              <div className="heading-text">Take a look at our clients</div>
              <div className="heading-desc">
                We did a great job with these companies. You can be next to work
                with!
              </div>
            </div>

            <div className="client-slider">
              <Slider className="slider-css" {...settings}>
                {clientImage.map((i) => (
                  <div className="slider" key={i.id}>
                    <img src={i.img} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div> */}

        {/* <Certification /> */}
      </div>
    </motion.div>
  );
}

export default Home;
