import React from "react";
import contentLogo from "../../assets/content-logo/Frame 4 1.png";
import contentLogo2 from "../../assets/content-logo/Frame 5 1.png";
import { motion } from "framer-motion";

import "./VisionSection.css";
function VisionSection() {
  return (
    <div>
      <div className="home-section3">
        <div className="section-heading">
          <div className="heading-text">vission & mission</div>
          <div className="heading-desc">
            what are we doing, and why are we doing it.
          </div>
        </div>
        <div className="section-content3">
          <div className="content3">
            <motion.div className="v-icon">
              <img src={contentLogo} alt="" />
            </motion.div>
            <div className="content3-heading">our vision</div>
            <div className="line"></div>
            <div className="content3-detail">
              To provide quality & trustworthy support, exceeding expectations
              by delivering 50% more value than the service charge.Pioneers in
              the document & business setup industry, we offer a relaxed
              customer experience under one roof. Drawing from our years of
              expertise, we commit to finding optimal solutions, sharing
              knowledge, and contributing to financial success. Gratitude guides
              us, expressing thanks for every step of our journey.
            </div>
          </div>

          <div className="content3">
            <motion.div className="v-icon">
              <img src={contentLogo2} alt="" />
            </motion.div>
            <div className="content3-heading">our vision</div>
            <div className="line"></div>
            <div className="content3-detail">
              Our goal is to manage 100+ environmently friendly, modern
              technology-equipped smart typing & job works business setup
              offices across the UAE. Looking ahead, Aapali envisions
              prioritizing customer value, providing more less, and supporting
              UAE nationals in business or job opportunities. With the backing
              of UAE leaders & advanced technology, we strive to utilize their
              blessing for the betterment of our community.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionSection;
