import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ContactUs.css";
import VisionSection from "../vision/VisionSection";
import Certification from "../../components/certification/Certification";
import callIcon from "../../assets/contactus/call.svg";
import emailIcon from "../../assets/contactus/email.svg";
import locIcon from "../../assets/contactus/location_on.svg";
import { motion } from "framer-motion";

function ContactUs() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <Container>
        <Row>
          <Col>
            <div className="banner8">
              <div className="overlay8"></div>
              <div className="title8">
                <div className="contact-us-heading"> Contact Us</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <div className="contact-heading-section">
              <div className="contact-heading">Contact our Friendly Team</div>
              <div className="contact-heading-desc">
                what are we doing, and why are we doing it.
              </div>
            </div>
          </div>
        </Row>
        <Row>
          <Col>
            <div className="contact-card">
              <motion.div
                whileHover={{ scale: 1.2, rotate: -20 }}
                whileTap={{ scale: 0.8, rotate: 90, borderRadius: "100%" }}
                className="contact-card-icon"
              >
                <img src={callIcon} alt=" call icon" />
              </motion.div>
              <div className="contact-card-title">call us</div>
              <div className="contact-card-title-desc">Sat-Thu</div>
              <div className="contact-card-detail">
                0091 971444 661 662(India) 00971 50 4462755(UAE)
              </div>
            </div>
          </Col>
          <Col>
            <div className="contact-card">
              <motion.div
                whileHover={{ scale: 1.2, rotate: -20 }}
                whileTap={{ scale: 0.8, rotate: 90, borderRadius: "100%" }}
                className="contact-card-icon"
              >
                <img src={emailIcon} alt=" email icon" />
              </motion.div>
              <div className="contact-card-title">email</div>
              <div className="contact-card-title-desc">send emails at</div>
              <div className="contact-card-detail">info@example.com</div>
            </div>
          </Col>
          <Col>
            <div className="contact-card">
              <motion.div
                whileHover={{ scale: 1.2, rotate: -20 }}
                whileTap={{ scale: 0.8, rotate: 90, borderRadius: "100%" }}
                className="contact-card-icon"
              >
                <img src={locIcon} alt=" location icon" />
              </motion.div>
              <div className="contact-card-title">visit us</div>
              <div className="contact-card-title-desc">visit our office</div>
              <div className="contact-card-detail">INDIA - UAE</div>
            </div>
          </Col>
        </Row>
      </Container>

      <VisionSection />
      {/* <Certification /> */}
    </motion.div>
  );
}

export default ContactUs;
