export const navItems = [
  {
    id: 2,
    title: "SERVICES",

    cName: "nav-item",
  },
  {
    id: 3,
    title: "ABOUT US",
    path: "/aboutus",
    cName: "nav-item",
  },
  {
    id: 4,
    title: "LOCATIONS",
    path: "/aboutus",
    cName: "nav-item",
  },
  {
    id: 5,
    title: "OUR TEAM",
    path: "/aboutus",
    cName: "nav-item",
  },
  {
    id: 6,
    title: "CONTACT US",
    path: "/contactus",
    cName: "nav-item",
  },
];

export const servicesDropdown = [
  {
    id: 12,
    title: "VAT/Tax Consultants",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 2,
    title: "Import-Export License Consultants",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 3,
    title: "IT, Network, and Security Specialist",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 4,
    title: "Real Estate and Investment Advisors",
    path: "/off-shore-on-shore-rental",
    cName: "submenu-item",
  },
  {
    id: 5,
    title: "Social Media and Digital Marketing Team",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 6,
    title: "Certified Legal Translators",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 7,
    title: "Legal Advisors",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 8,
    title: "Multilingual Typists",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 9,
    title: "Travel /Ticketing Professionals",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 10,
    title: "Promotion/Photo Studio Services",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 11,
    title: "Printing  and Design Experts ",
    path: "/",
    cName: "submenu-item",
  },
  {
    id: 12,
    title: "Business Setup Professionals",
    path: "/",
    cName: "submenu-item",
  },
];
