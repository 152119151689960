import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./AboutUs.css";
import VisionSection from "../vision/VisionSection";
import Certification from "../../components/certification/Certification";
import { motion } from "framer-motion";

function AboutUs() {
  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth }}
    >
      <Container>
        <Row>
          <Col>
            <div className="oil-banner7">
              <div className="oil-title7">
                <div className="about-us-heading">
                  {" "}
                  Aapali change you & your life
                </div>
              </div>
              <div className="overlay7"></div>
            </div>
          </Col>
        </Row>
        <Row>
          <div>
            <div className="about-us-decs">
              Aapali is the culmination of the efforts of dedicated individuals
              and a professional team boasting over 40 years of collective
              experience across diverse industries. Our expertise spans business
              setup, including typing centers, translation offices, VAT/tax
              consultancy, educational institutions, document and shipment
              delivery offices, and the establishment of resorts and farmhouses.
              We've also navigated various roles, from grocery sales to
              positions in accounting, secretarial work, purchasing,
              receptionist duties, translation, auditing, tax consultancy,
              training, tour operator, and more. Our journey encompasses
              experience in supermarkets, travel and tourism, accounts,
              purchases, and warehouse management.
            </div>
          </div>
        </Row>
      </Container>

      <VisionSection />
      {/* <Certification /> */}
    </motion.div>
  );
}

export default AboutUs;
